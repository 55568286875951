import { Fragment } from "react";
import { useSelector } from "react-redux";

const EShop = (props) => {
  const translate = useSelector((state) => state.AdminReducer.translate);
  return (
    <Fragment>
      <section
        id="e-shop"
        className={[
          !props.template ? "temp-1" : "temp-" + props.template,
          !props.theme ? "light" : props.theme,
        ].join(" ")}
      >
        <h2>{translate('visit_our_eshop')}</h2>
        <div className="e-shop-button__container">
          <a
            href="https://www.delhaizeproxymuizen.be/extra/webshop/"
            className="e-shop-button"
          >
            {translate('our_eshop')}
          </a>
          <a
            href="https://www.delhaize.be/nl-be/shop"
            className="e-shop-button"
          >
            DELHAIZE E-SHOP
          </a>
        </div>
      </section>
    </Fragment>
  );
};

export default EShop;
